

import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./components/Layout";
import CaliforniaPhotoshop from "./pages/CaliforniaPhotoshop";
import Home from "./pages/Home";


const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path={"/"} element={<Home />}/>
                    <Route path={"/california"} element={<CaliforniaPhotoshop />}/>
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}


export default App;
