

import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    title: "error",
    text: "This is an error alert — check it out!",
    isOpened: false,
};


const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setOpenAlert(state, {payload}) {
            state.title = payload.title;
            state.text = payload.text;
            state.isOpened = true;
        },
        setCloseAlert(state) {
            state.isOpened = false;
        }
    },
})


export const {
    setOpenAlert,
    setCloseAlert,
} = alertSlice.actions;

export default alertSlice.reducer;
