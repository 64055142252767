
/**
 * @param {Object} props
 * @param {React.CSSProperties} [props.style]
 */
const BaseDateField = ({
    value,
    style,
    onChange,
}) => {
    return (
        <input
            value={value}
            maxLength={10}
            style={{maxWidth: "400px", ...style}}
            placeholder={"01/01/0101"}
            onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, '')
                let formattedValue = '';

                if (value.length === 1) {
                    if (value !== "1" && value !== "0") {
                        value = ""
                    }
                }
                if (value.length >= 1) {
                    formattedValue = value.slice(0, 2); // MM
                    if (parseInt(formattedValue) > 12){
                        formattedValue = value.slice(0, -1)
                    }
                    if (parseInt(formattedValue) === 0 && value.length > 1) {
                        formattedValue = value.slice(0, -1)
                    }
                }
                if (value.length >= 3) {
                    if (parseInt(value[2]) > 3) {
                        value = value.slice(0, -1);
                    }
                    if (parseInt(value.slice(2, 4)) > 31) {
                        value = value.slice(0, -1)
                    }
                    if (parseInt(value.slice(2, 4)) === 0 && value.length > 3) {
                        value = value.slice(0, -1)
                    }
                    formattedValue += '/' + value.slice(2, 4); // DD
                }
                if (value.length >= 5) {
                    if (parseInt(value[4]) > 2 || value[4] === "0") {
                        value = value.slice(0, -1);
                    }
                    if (value.length === 6 && value.slice(4, 6) !== "20" && value.slice(4, 6) !== "19") {
                        value = value.slice(0, -1)
                    }
                    formattedValue += '/' + value.slice(4, 8); // YYYY
                }
                e.target.value = formattedValue;
                onChange(e)
            }}
        />
    );
};


export default BaseDateField;
