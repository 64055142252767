

import {useGenerateMutation} from "../../../../redux/api/auth";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Box, Skeleton} from "@mui/material";


const hasRequiredAttributes = (obj, attributes) => {
    return attributes.every(attr => obj[attr] !== "");
};


const Result = () => {
    const state = useSelector(state => state.california);
    const [generate, { isLoading, data, error, isError }] = useGenerateMutation();
    const [canGenerate, setCanGenerate] = useState(false)
    const requiredAttributes = [
        "number", "exp", "lastName", "firstName", "middleName", "street", "city", "zip", "dob",
        "rstr", "class_", "end", "sex", "hair", "eyes", "wgt", "donor", "commercial_dl",
        "hgt_feet", "hgt_inch", "iss", "dd", "inventoryNumber", "realId", "veteran",
    ]
    useEffect( () => {
        (async () => {
            if (hasRequiredAttributes(state, requiredAttributes)) {
                setCanGenerate(true)
                await generate(state)
            }
            else {
                setCanGenerate(false)
            }

        })()
    }, [state]);

    return (
        <Box
            sx={{
                position: "absolute",
                top: "803px",
                left: "103px",
                width: "1169px",
                height: "268px",
                cursor: "pointer",
                "& *": {
                    width: "100%!important",
                    height: "100%!important",
                }
            }}
            title={data?.aamva_header}
            onClick={e => {
                const img = e.target?.firstElementChild;
                if (img?.src)
                window.open(img.src, "_blank")
            }}
        >
            {
                isLoading || isError || !canGenerate ?
                    <Skeleton
                        variant="rectangular"
                        animation={false}
                        sx={{
                            bgcolor: !canGenerate ?
                                "#898"
                                :
                                (isLoading ? '#779' : "#977"),
                            cursor: canGenerate && isLoading ? 'wait' : "not-allowed",
                        }}
                        title={!canGenerate ?
                            "Fill all fields"
                            :
                            (isLoading ? 'Loading...' : JSON.stringify(error?.data))
                        }
                    />
                    :
                    <img src={data?.image} />
            }
        </Box>
    );
};


export default Result;
