

import {setDocDiscriminator} from "../../../../../redux/slices/californiaSlice";
import {useDispatch, useSelector} from "react-redux";


const DocumentDiscriminator = () => {
    const dispatch = useDispatch()
    const {dd} = useSelector((state) => state.california);
    // V25ANS
    return (
        <input
            value={dd}
            maxLength={23}
            style={{
                color: '#010101',
                top: "1166px",
                left: "989px",
                fontSize: "41.67px",
                width: "625px",
                letterSpacing: "0.4px",
            }}
            placeholder={"MM/DD/YYYYRRREE/GGRE/XX"}
            onChange={(e) => {
                let value = e.target.value.toUpperCase().replace(/[^0-9A-Z]/g, '')
                let formattedValue = '';

                if (value.length >= 1) {
                    formattedValue += value.slice(0, 2).replace(/\D/g, '');
                }
                if (value.length >= 3) {
                    formattedValue += '/' + value.slice(2, 4).replace(/\D/g, '');
                }
                if (value.length >= 5) {
                    formattedValue += '/' + value.slice(4, 8).replace(/\D/g, '');
                }
                if (value.length >= 9) {
                    formattedValue += value.slice(8, 11).replace(/\D/g, '');
                }
                if (value.length >= 12) {
                    formattedValue += value.slice(11, 13).replace(/[^A-Z0-9]/gi, '');
                }
                if (value.length >= 14) {
                    formattedValue += '/' + value.slice(13, 15).replace(/[^A-Z0-9]/gi, '');
                }
                if (value.length >= 16) {
                    formattedValue += value.slice(15, 17).replace(/[^A-Z]/gi, '');
                }
                if (value.length >= 18) {
                    formattedValue += '/' + value.slice(17, 19).replace(/\D/g, '');
                }
                e.target.value = formattedValue
                dispatch(setDocDiscriminator(formattedValue))
            }}
        />
    );
};


export default DocumentDiscriminator;
