

import {Box} from "@mui/material";
import Controls from "../components/Layers/California/Controls";
import BackSide from "../components/Layers/California/Back/BackSide";
import FrontSide from "../components/Layers/California/Front/FrontSide";


const CaliforniaPhotoshop = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <FrontSide/>
            <Controls />
            <BackSide/>
        </Box>
    );
};


export default CaliforniaPhotoshop;
