

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
    }),
    endpoints: (builder) => ({
        generate: builder.mutation({
            query: (params) => ({
                url: `/generate`,
                method: 'POST',
                body: params
            }),
        }),
    }),
})


export const { useGenerateMutation } = authApi;
