

const DocumentType = ({
    isRealId,
    isCommercialDL
}) => {
    return (
        <img
            src={`/static/psd/front/${isCommercialDL ? "CDL" : "DL"}_${isRealId ? "REAL_ID" : "FEDERAL"}.png`}
            alt={"doctype"}
            style={{
                top: "64px",
                left: "186px",
                padding: isRealId ?
                    0
                    :
                    `${isCommercialDL ? 2 : 20}px 40px 3px 1px`,
                zIndex: 9999,
            }}
        />
    );
};


export default DocumentType;
