

import {useDispatch, useSelector} from "react-redux";
import {setSex} from "../../../../../redux/slices/californiaSlice";


const Sex = () => {
    const dispatch = useDispatch()
    const {sex} = useSelector((state) => state.california);
    return (
        <select
            value={sex}
            style={{
                color: '#F010101',
                top: "1029px",
                left: "1037px",
                width: "80px",
            }}
            onChange={(e) => {
                dispatch(setSex(e.target.value));
            }}
        >
            <option disabled hidden value="">?</option>
            <option style={{color: "#4A90E2"}} value="1">M</option>
            <option style={{color: "#E94E77"}} value="2">F</option>
            <option style={{color: "#9B9B9B"}} value="9">X</option>
        </select>
    );
};


export default Sex;
