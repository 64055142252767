

import {useDispatch, useSelector} from "react-redux";
import {setHair} from "../../../../../redux/slices/californiaSlice";


const Hair = () => {
    const dispatch = useDispatch()
    const {hair} = useSelector((state) => state.california);
    return (
        <select
            value={hair}
            style={{
                color: '#010101',
                top: "1029px",
                left: "1382px",
                width: "150px",
            }}
            onChange={(e) => {
                dispatch(setHair(e.target.value))
            }}
        >
            <option disabled hidden value="">?</option>
            <option style={{color: "#333333"}} value="BAL">BAL</option>
            <option style={{color: "#000000"}} value="BLK">BLK</option>
            <option style={{color: "#DAA520"}} value="BLN">BLN</option>
            <option style={{color: "#8B4513"}} value="BRN">BRN</option>
            <option style={{color: "#708090"}} value="GRY">GRY</option>
            <option style={{color: "#FF0000"}} value="RED">RED</option>
            <option style={{color: "#F4A460"}} value="SDY">SDY</option>
            <option style={{color: "#AAAAAA"}} value="WHI">WHI</option>
            <option style={{color: "#808080"}} value="UNK">UNK</option>
        </select>
    );
};


export default Hair;
