

import {Box, Link, Typography} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';


const Home = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: 'center',
                backgroundColor: "background.default",
                p: "30px",
                width: "1000px",
                m: "auto",
                borderRadius: "30px",
                gap: "30px"
            }}
        >
            <Typography variant="h2" color={"text.primary"}>
                Home
            </Typography>
            <Link component={RouterLink} to={"/california"}>
                California
            </Link>
        </Box>
    );
};


export default Home;
