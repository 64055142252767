

const Background = ({
    front,
    back,
}) => {
    const side = front ?
        "front"
        :
        (back ? "back" : undefined);
    return (
        <img
            // src={`/static/psd/${side}/tempfull.png`}
            src={`/static/psd/${side}/background.png`}
            alt={"background"}
            style={{
                width: "100%",
                height: "100%",
                zIndex: "0",
            }}
        />
    );
};


export default Background;
