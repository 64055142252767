

import {setFirstName} from "../../../../../redux/slices/californiaSlice";
import {useDispatch, useSelector} from "react-redux";


const FirstName = () => {
    // V25ANS
    const dispatch = useDispatch()
    const {firstName} = useSelector(state => state.california);

    const adjustWidth = (e) => {
        let value = e.target.value.toUpperCase().trimStart().replace(/[^A-Z\s\-.]/g, "");
        dispatch(setFirstName(value))
        let widthCalc = document.getElementById("fNameWidthCalc")

        widthCalc.style.font = getComputedStyle(e.target).font;
        widthCalc.textContent = value;
        e.target.style.width = `${widthCalc.offsetWidth+1}px`;
    }

    return (
        <>
            <input
                maxLength={25}
                value={firstName}
                style={{
                    position: "static",
                    color: '#010101',
                    fontSize: "66.67px",
                    minWidth: "50px",
                    width: "230px"
                }}
                placeholder={"BORIS"}
                onChange={adjustWidth}
            />
            <span
                style={{
                    position: "absolute",
                    color: '#010101',
                    fontSize: "66.67px",
                    zIndex: -999,
                }}
                id={"fNameWidthCalc"}
            />
            <span
                style={{
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "66.67px",
                }}
            >
                &nbsp;
            </span>
        </>
    );
};


export default FirstName;
