

import Background from "../Front/Background";
import {Box} from "@mui/material";
import { BakeryCode128 } from '@barcode-bakery/barcode-react/1d';
import {useSelector} from "react-redux";
import Result from "./Result";


const BackSide = () => {
    const {
        inventoryNumber, realId, controlsHidden, scale,
        class_, rstr
    } = useSelector(state => state.california);
    const {dob} = useSelector(state => state.california)
    let dobPrepared = dob.replace(/\D/g, "")
    dobPrepared = dobPrepared.slice(0, 4) + dobPrepared.slice(6);
    dobPrepared = dobPrepared.split('')
    return (
        <Box
            id={"back"}
            sx={{
                transform: `scale(${scale})`,
                width: "2032px",
                height: "1276px",
                backgroundColor: "white",
                overflow: "hidden",
                borderRadius: "80px",
                position: "relative",
                "& img": {
                    backgroundColor: "transparent",
                    position: "absolute",
                    userSelect: "none",
                    pointerEvents: "none",
                },
                "& input, & select": {
                    position: 'absolute',
                    backgroundColor: controlsHidden ? "transparent" : "#01010130",
                    border: 0,
                    outline: 0,
                    padding: 0,
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "50px",
                    appearance: controlsHidden ? "none" : "auto",
                    // textShadow: "0px 0px 29px #01010166",
                },
            }}
        >
            <Background back/>
            <Box
                sx={{
                    zIndex: 2,
                    mixBlendMode: "darken",
                    opacity: 0.8,
                    filter: "url(#strong-noise) blur(0.3px)",
                    "& canvas": {
                        width: "1139px",
                        height: "91.2px",
                        position: "absolute",
                        top: 0,
                        left: "710px",
                    }
                }}
            >
                <div
                    style={{cursor: "pointer"}}
                    onClick={(e) => {
                        e.target.toBlob(blob => {
                            const url = URL.createObjectURL(blob);
                            window.open(url, '_blank');
                            URL.revokeObjectURL(url);
                        });
                    }}
                    title={inventoryNumber}
                >
                    <BakeryCode128 text={inventoryNumber} thickness={9} scale={10} label={""}/>
                </div>
                {class_ && rstr &&
                    <img
                        src={`/static/psd/back/${class_}_${rstr}.png`}
                        alt={"text"}
                        style={{
                            fontSize: "41.67px",
                            position: "absolute",
                            top: "494px",
                            left: "285px",
                        }}
                    />}
                {realId !== undefined &&
                    <img
                        src={`/static/psd/back/${realId ? "real" : "federal"}.png`}
                        alt={"text"}
                        style={{
                            left: "1360px",
                            top: "777px"
                        }}
                    />
                }
                <div
                    style={{
                        position: "absolute",
                        top: "1181px",
                        left: "1638px",
                        fontSize: "33.33px",
                        fontFamily: "Arial",
                        fontWeight: "normal",
                        letterSpacing: "0.2px"
                    }}
                >
                    {inventoryNumber}
                </div>
                <Result/>
            </Box>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    position: "absolute",
                    top: "1034px",
                    left: "1310px",
                    zIndex: 2,
                }}
            >
                {dobPrepared.map((item, index) => (
                    <img
                        key={index}
                        src={`/static/psd/back/digits/${item}.png`}
                        alt={item}
                        style={{
                            position: "static",
                            margin: "-8.4px 0",
                    }}
                    />
                ))}
            </div>
        </Box>
    );
};


export default BackSide;
