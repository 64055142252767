

import BaseDateField from "./BaseDateField";
import {setIssuedDate} from "../../../../../redux/slices/californiaSlice";
import {useDispatch, useSelector} from "react-redux";


const DateOfIssue = () => {
    const dispatch = useDispatch()
    const {iss} = useSelector((state) => state.california);
    return (
        <BaseDateField
            value={iss}
            style={{
                color: '#010101',
                top: "1154px",
                left: "1697px",
                letterSpacing: "-0.5px"
            }}
            onChange={(e) => {
                dispatch(setIssuedDate(e.target.value))
            }}
        />
    );
};


export default DateOfIssue;
