

import {useDispatch, useSelector} from "react-redux";
import {setClass} from "../../../../../redux/slices/californiaSlice";


const DClass = () => {
    const dispatch = useDispatch()
    const {class_} = useSelector((state) => state.california);
    return (
        <select
            value={class_}
            style={{
                color: '#010101',
                top: "290px",
                left: "1576px",
                width: "130px",
            }}
            onChange={e => {
                dispatch(setClass(e.target.value))
            }}
        >
            <option disabled hidden value="">?</option>
            <option value="A">A</option>
            <option value="C">C</option>
            {/*<option value="CM1">CM1</option>*/}
        </select>
    );
};


export default DClass;
