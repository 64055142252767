

import {setRestrictions} from "../../../../../redux/slices/californiaSlice";
import {useDispatch, useSelector} from "react-redux";


const Restrictions = () => {
    const dispatch = useDispatch()
    const {rstr} = useSelector((state) => state.california);
    return (
        <select
            defaultValue={rstr}
            style={{
                color: '#010101',
                top: "842px",
                left: "852px",
                width: "330px",
            }}
            onChange={e => {
                dispatch(setRestrictions(e.target.value))
            }}
        >
            <option disabled hidden value="">?</option>
            <option value="NONE">NONE</option>
            <option value="01">CORR LENS</option>
        </select>
    );
};


export default Restrictions;
