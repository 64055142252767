

import {Toolbar, Typography} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';


const TopHeader = () => {
    return (
        <Toolbar sx={{gap: "10px"}}>
            <Typography
                variant="h5"
                color={"textPrimary"}
                component={RouterLink} to={"/"}
                sx={{
                    flexGrow: 1,
                    textDecoration: "none",
                    textAlign: "center"
                }}
            >
                BoysMineCash generator
            </Typography>
        </Toolbar>
    );
};


export default TopHeader;
