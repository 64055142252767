

import BaseDateField from "./BaseDateField";
import {useDispatch, useSelector} from "react-redux";
import {setExp} from "../../../../../redux/slices/californiaSlice";


const DateOfExp = () => {
    const dispatch = useDispatch()
    const {exp} = useSelector((state) => state.california);
    return (
        <BaseDateField
            value={exp}
            style={{
                color: '#84222a',
                top: "402.5px",
                left: "815px",
                fontSize: "75px",
                letterSpacing: "0.75px"
            }}
            onChange={(e) => {
                dispatch(setExp(e.target.value))
            }}
        />
    );
};


export default DateOfExp;
