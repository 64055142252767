import {useDispatch, useSelector} from "react-redux";
import {setDocDiscriminator, setExp} from "../../../../redux/slices/californiaSlice";
import {generateRandomSequence, getRandomCharacter, getRandomNumberInRange} from "../../../../utils/randomGen";
import {setOpenAlert} from "../../../../redux/slices/alertSlice";


const GenButtons = () => {
    const {dob, iss, exp} = useSelector(state => state.california)
    const dispatch = useDispatch();
    return (
        <div
            style={{
                zIndex: 999,
            }}
        >
            <button
                style={{
                    position: "absolute",
                    top: "410px",
                    left: "1220px",
                    fontSize: "50px",
                }}
                onClick={() => {
                    let dobDate = dob.replace(/[^0-9/]/g, "")
                    let issuedDate = iss.replace(/[^0-9/]/g, "")
                    if (!dobDate || dobDate.length !== 10)
                        return dispatch(setOpenAlert({
                            title: "Error",
                            text: "Fill DOB date first"
                        }))
                    if (!issuedDate || issuedDate.length !== 10)
                        return dispatch(setOpenAlert({
                            title: "Error",
                            text: "Fill ISSUED date first"
                        }))
                    dispatch(setExp(`${dobDate.slice(0, 5)}/${5 + parseInt(issuedDate.slice(-4))}`))
                }}
            >
                Gen
            </button>
            <button
                style={{
                    position: "absolute",
                    top: "1155px",
                    left: "1520px",
                    fontSize: "50px",
                }}
                onClick={() => {
                    let issuedDate = iss.replace(/[^0-9/]/g, "")
                    let expDate = exp.replace(/[^0-9/]/g, "")
                    if (!issuedDate || issuedDate.length !== 10)
                        return dispatch(setOpenAlert({
                            title: "Error",
                            text: "Fill ISSUED date first"
                        }))
                    if (!expDate || expDate.length !== 10)
                        return dispatch(setOpenAlert({
                            title: "Error",
                            text: "Fill EXPIRED date first"
                        }))
                    dispatch(setDocDiscriminator(`${issuedDate}${getRandomNumberInRange(100, 999)}${generateRandomSequence(2)}/${getRandomCharacter().repeat(2)}FD/${expDate.slice(-2)}`))
                }}
            >
                Gen
            </button>
        </div>
    );
};


export default GenButtons;
