

const Endorsements = () => {
    return (
        <input
            disabled
            value={"NONE"}
            style={{
                color: '#010101',
                top: "421px",
                left: "1514px",
                letterSpacing: "-0.1px",
                width: "150px"
            }}
            onChange={(e) => {
                e.target.value = e.target.value.toUpperCase()       // todo: validate format
            }}
        />
    );
};


export default Endorsements;
