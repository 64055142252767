

import {useSelector} from "react-redux";


const Combined = () => {
    const {firstName, lastName, dob} = useSelector(state => state.california);

    const text = (firstName ? firstName[0] : "?")
        + (lastName ? lastName[0] : "?")
        + (dob.replace(/\D/g, "").slice(6, 8) ? dob.replace(/\D/g, "").slice(6, 8) : "??")

    return (
        <div
            style={{
                textTransform: 'uppercase',
                fontFamily: "Arial",
                fontWeight: 'normal',
                fontSize: '16.67px',
                userSelect: "none",
            }}
        >
            <span
                style={{
                    position: "absolute",
                    top: "827px",
                    left: "703px",
                    color: '#0C1E6A',
                    letterSpacing: "0.5px",
                }}
            >
                {text}
            </span>
            <span
                style={{
                    position: "absolute",
                    top: "1047.5px",
                    left: "994.5px",
                    color: '#0C1E6A',
                    transform: "rotate(-90deg)",
                    letterSpacing: "0.5px",
                }}
            >
                {text}
            </span>
            <span
                style={{
                    position: "absolute",
                    top: "1102px",
                    left: "1629px",
                    color: '#010101',
                    letterSpacing: "2px",
                }}
            >
                {text}
            </span>
        </div>
    );
};


export default Combined;
