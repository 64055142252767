

import {useDispatch, useSelector} from "react-redux";
import {setHeightFeet, setHeightInch} from "../../../../../redux/slices/californiaSlice";


const Height = () => {
    const dispatch = useDispatch()
    const {hgt_feet, hgt_inch} = useSelector((state) => state.california);
    return (
        <div
            style={{
                position: 'absolute',
                display: 'flex',
                top: "1087px",
                left: "1037px",
            }}
        >
            <input
                value={hgt_feet}
                maxLength={1}
                style={{
                    position: "static",
                    color: '#010101',
                    width: "57px",
                }}
                placeholder={"0"}
                onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, "")
                    dispatch(setHeightFeet(value))
                }}
            />
            <span
                style={{
                    position: 'absolute',
                    left: "25px",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "50px",
                    color: "#010101"
                }}
            >
                ′-
            </span>
            <input
                value={hgt_inch}
                maxLength={2}
                style={{
                    position: "static",
                    color: '#010101',
                    width: "54px",
                }}
                placeholder={"00"}
                onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, "")
                    dispatch(setHeightInch(value))
                }}
            />
            <span
                style={{
                    position: 'absolute',
                    left: "107px",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "50px",
                    color: "#010101"
                }}
            >
                ″
            </span>
        </div>
    );
};


export default Height;
