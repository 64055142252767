

import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    number: "",
    exp: "",
    lastName: "",
    firstName: "",
    middleName: "",
    street: "",
    city: "",
    zip: "",
    dob: "",
    rstr: "",
    class_: "",
    end: "NONE",
    sex: "",
    hair: "",
    eyes: "",
    wgt: "",
    hgt_feet: "",
    hgt_inch: "",
    iss: "",
    dd: "",
    inventoryNumber: "",
    realId: undefined,
    donor: undefined,
    veteran: undefined,
    commercial_dl: undefined,

    controlsHidden: true,
    genButtonsHidden: false,
    scale: 1,
};


const californiaSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        setNumber(state, {payload}) {
            state.number = payload;
        },
        setExp(state, {payload}) {
            state.exp = payload;
            state.dob = payload.slice(0, 6) + state.dob?.slice(6);
        },
        setLastName(state, {payload}) {
            state.lastName = payload;
        },
        setFirstName(state, {payload}) {
            state.firstName = payload;
        },
        setMiddleName(state, {payload}) {
            state.middleName = payload;
        },
        setStreet(state, {payload}) {
            state.street = payload;
        },
        setCity(state, {payload}) {
            state.city = payload;
        },
        setZip(state, {payload}) {
            state.zip = payload;
        },
        setDob(state, {payload}) {
            state.dob = payload;
            state.exp = payload.slice(0, 6) + state.exp?.slice(6);
        },
        setRestrictions(state, {payload}) {
            state.rstr = payload;
        },
        setClass(state, {payload}) {
            state.class_ = payload;
        },
        setSex(state, {payload}) {
            state.sex = payload
        },
        setHair(state, {payload}) {
            state.hair = payload;
        },
        setEyes(state, {payload}) {
            state.eyes = payload;
        },
        setWeight(state, {payload}) {
            state.wgt = payload;
        },
        setHeightFeet(state, {payload}) {
            state.hgt_feet = payload;
        },
        setHeightInch(state, {payload}) {
            state.hgt_inch = payload;
        },
        setIssuedDate(state, {payload}) {
            state.iss = payload
        },
        setDocDiscriminator(state, {payload}) {
            state.dd = payload
        },
        setInventoryNumber(state, {payload}) {
            state.inventoryNumber = payload
        },
        setRealId(state, {payload}) {
            state.realId = payload;
        },
        setDonor(state, {payload}) {
            state.donor = payload
        },
        setVeteran(state, {payload}) {
            state.veteran = payload;
        },
        setCommercialDl(state, {payload}) {
            state.commercial_dl = payload
        },
        setControlsHidden(state, {payload}) {
            state.controlsHidden = payload;
        },
        setGenButtonsHidden(state, {payload}) {
            state.genButtonsHidden = payload;
        },
        setScale(state, {payload}) {
            state.scale = payload
        },
    },
})


export const {
    setNumber,
    setExp,
    setLastName,
    setFirstName,
    setMiddleName,
    setStreet,
    setCity,
    setZip,
    setDob,
    setRestrictions,
    setClass,
    setSex,
    setHair,
    setEyes,
    setWeight,
    setHeightFeet,
    setHeightInch,
    setIssuedDate,
    setDocDiscriminator,
    setInventoryNumber,
    setRealId,
    setDonor,
    setVeteran,
    setCommercialDl,
    setControlsHidden,
    setGenButtonsHidden,
    setScale,
} = californiaSlice.actions;

export default californiaSlice.reducer;
