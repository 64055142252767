

import {createTheme} from "@mui/material";


const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#F7A600",
        },
        secondary: {
            main: "#707378",
        },
        background: {
            default: "#25282DD0",
            paper: "#100F14",
        },
        text: {
            primary: "#FEFEFE",
            secondary: "#707378",
            disabled: "#404040",
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
});

export default darkTheme;
