

import BaseDateField from "./BaseDateField";
import {useDispatch, useSelector} from "react-redux";
import {setDob} from "../../../../../redux/slices/californiaSlice";


const DateOfBirth = () => {
    const dispatch = useDispatch()
    const {dob} = useSelector(state => state.california)
    return (
        <>
            <BaseDateField
                value={dob}
                style={{
                    color: '#84222a',
                    top: "755px",
                    left: "825px",
                    fontSize: "75px",
                    letterSpacing: "0.75px"
                }}
                onChange={(e) => {
                    dispatch(setDob(e.target.value))
                }}
            />
            <span
                style={{
                    color: "#010101",
                    position: 'absolute',
                    top: "820.5px",
                    left: "1641px",
                    fontSize: "68px",
                    fontWeight: "bold",
                    fontFamily: "Arial",
                    letterSpacing: "0.2px"
                }}
            >
                {dob.replace(/[^0-9]/g, "")}
            </span>
        </>
    );
};


export default DateOfBirth;
