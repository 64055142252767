

import {useDispatch, useSelector} from "react-redux";
import {setMiddleName} from "../../../../../redux/slices/californiaSlice";


const MiddleName = () => {
    // V40ANS
    const dispatch = useDispatch()
    const {middleName} = useSelector(state => state.california);
    return (
        <input
            maxLength={40}
            value={middleName}
            style={{
                position: "static",
                color: '#010101',
                fontSize: "66.67px",
                width: middleName === "NONE" ? "200px" : "auto"
            }}
            placeholder={"BLACK"}
            onChange={(e) => {
                let value = e.target.value.toUpperCase().trimStart()
                value = value.replace(/[^A-Z\d\s-.]/g, "")
                dispatch(setMiddleName(value))
            }}
        />
    );
};


export default MiddleName;
