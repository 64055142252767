

import {Alert, AlertTitle, Box, Button} from "@mui/material";
import {setCloseAlert} from "../redux/slices/alertSlice";
import {useDispatch, useSelector} from "react-redux";


const AlertDialog = () => {
    const dispatch = useDispatch();
    const {title, text, isOpened} = useSelector((state) => state.alert);
    if (!isOpened)
        return undefined
    return (
        <Box
            sx={{
                display: "flex",
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 99999,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#000A",
                cursor: "not-allowed",
            }}
        >
            <Alert
                severity="error"
                sx={{ minWidth: "600px", borderRadius: "20px", cursor: "default"}}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                            dispatch(setCloseAlert())
                        }}
                    >
                        CLOSE
                    </Button>
                }
            >
                <AlertTitle>
                    {title}
                </AlertTitle>
                {text}
            </Alert>
        </Box>
    );
};


export default AlertDialog;
