

import {useDispatch, useSelector} from "react-redux";
import {setWeight} from "../../../../../redux/slices/californiaSlice";


const Weight = () => {
    const dispatch = useDispatch()
    const {wgt} = useSelector((state) => state.california);
    const adjustWidth = (e) => {
        let value = e.target.value.replace(/\D/g, "")
        let widthCalc = document.getElementById("heightCalc")

        widthCalc.style.font = getComputedStyle(e.target).font;
        widthCalc.textContent = value;
        e.target.style.width = `${widthCalc.offsetWidth + 2}px`;
        dispatch(setWeight(value))
    }
    return (
        <div
            style={{
                position: 'absolute',
                display: 'flex',
                top: "1087px",
                left: "1382px",
            }}
        >
            <input
                value={wgt}
                id={"height"}
                maxLength={3}
                style={{
                    position: "static",
                    color: '#010101',
                    letterSpacing: "-1px",
                    minWidth: "70px",
                    width: "57px",
                }}
                placeholder={"000"}
                onChange={adjustWidth}
            />
            <span
                style={{
                    position: "absolute",
                    color: '#010101',
                    zIndex: -999,
                    letterSpacing: "-1px"
                }}
                id={"heightCalc"}
            />
            <span
                style={{
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "50px",
                    color: "#010101",
                    letterSpacing: "-1px",
                    paddingLeft: "10px"
                }}
            >
                lb
            </span>
        </div>
    );
};


export default Weight;
