

import {Box} from "@mui/material";
import {useSelector} from "react-redux";


const RawPhoto = () => {

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                transform: "scale(1.5) translateY(5%)",
                filter: `blur(0.3px) brightness(0.75) contrast(1.25) drop-shadow(0 0.025vw 0.01vw rgba(0, 0, 0, 0.73)) blur(0.3px)`
            }}
        >
            <img
                style={{
                    width: "100%", height: "100%",
                    objectFit: "cover",
                }}
                src={`/static/psd/front/cat_photo.png`}
                alt={"photo"}
            />
            <div style={{
                position: 'absolute',
                top: 0,
                left: '50%',
                width: '25%',
                height: '100%',
                backgroundColor: '#FFFFFF',
                transform: 'translateX(-50%)',
                opacity: "50%",
                mixBlendMode: "overlay",
                filter: `blur(3vw)`,
            }}/>
        </Box>
    )
}


const Photo = () => {
    const {dob} = useSelector(state => state.california)
    let dobPrepared = dob.replace(/\D/g, "")
    dobPrepared = dobPrepared.slice(0, 4) + dobPrepared.slice(6);
    dobPrepared = dobPrepared.split('')
    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    overflow: "hidden",
                    aspectRatio: 3 / 4,
                    left: "88.1px",
                    right: "1347.4px",
                    bottom: "245px",
                    zIndex: 2
                }}
            >
                <RawPhoto/>
            </Box>

            <div
                style={{
                    display: "flex",
                    position: "absolute",
                    top: "850px",
                    left: "185px",
                    height: "109px",
                    zIndex: 2,
                    opacity: 0.7
                }}
            >
                {dobPrepared.map((item, index) => (
                    <img
                        key={index}
                        src={`/static/psd/front/digits/${item}.png`}
                        alt={item}
                        style={{position: "static"}}
                    />
                ))}
            </div>

            {/*<Box*/}
            {/*    sx={{*/}
            {/*        position: "absolute",*/}
            {/*        overflow: "hidden",*/}
            {/*        aspectRatio: 3 / 4,*/}
            {/*        top: "55.3%",*/}
            {/*        left: "62.3%",*/}
            {/*        right: "26.05%",*/}
            {/*        mixBlendMode: 'multiply',*/}
            {/*        opacity: "30%",*/}
            {/*        filter: "hue-rotate(40deg) saturate(30%) brightness(115%) contrast(150%) blur(0.05vw)"*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <RawPhoto viewportWidth={viewportWidth}/>*/}
            {/*</Box>*/}
            <img
                src={"/static/psd/front/after_photo.png"}
                alt={"hover"}
                style={{
                    width: "100%",
                    height: "100%",
                    zIndex: 3,
                    mixBlendMode: "difference",
                    filter: "blur(0.5px) contrast(90%)",
                    opacity: "80%"
                }}
            />
        </>
    );
};


export default Photo;
