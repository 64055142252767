

import {useDispatch, useSelector} from "react-redux";
import {setZip} from "../../../../../redux/slices/californiaSlice";


const ZipCode = () => {
    const dispatch = useDispatch()
    const {zip} = useSelector(state => state.california);
    return (
        <input
            value={zip}
            maxLength={10}
            style={{
                position: "static",
                color: '#010101',
                width: "300px",
                letterSpacing: "-0.1px",
                paddingLeft: "12px"
            }}
            placeholder={"90909-0000"}
            onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, '')
                if (value.length > 5) {
                    value = value.slice(0, 5) + '-' + value.slice(5, 9);
                }
                dispatch(setZip(value))
            }}
        />
    );
};


export default ZipCode;
