

import {useDispatch, useSelector} from "react-redux";
import {setEyes} from "../../../../../redux/slices/californiaSlice";


const Eyes = () => {
    const dispatch = useDispatch()
    const {eyes} = useSelector((state) => state.california);
    return (
        <select
            value={eyes}
            style={{
                color: '#010101',
                top: "1029px",
                left: "1738px",
                width: "150px",
            }}
            onChange={e => {
                dispatch(setEyes(e.target.value))
            }}
        >
            <option disabled hidden value="">?</option>
            <option style={{ color: "#000000" }} value="BLK">BLK</option>
            <option style={{ color: "#0000FF" }} value="BLU">BLU</option>
            <option style={{ color: "#8B4513" }} value="BRN">BRN</option>
            <option style={{ color: "#708090" }} value="GRY">GRY</option>
            <option style={{ color: "#006400" }} value="GRN">GRN</option>
            <option style={{ color: "#DAA520" }} value="HAZ">HAZ</option>
            <option style={{ color: "#800000" }} value="MAR">MAR</option>
            <option style={{ color: "#FFC0CB" }} value="PNK">PNK</option>
            <option style={{ color: "#4B0082" }} value="DIC">DIC</option>
            <option style={{ color: "#808080" }} value="UNK">UNK</option>
        </select>
    );
};


export default Eyes;
