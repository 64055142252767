

import {useDispatch, useSelector} from "react-redux";
import {setStreet} from "../../../../../redux/slices/californiaSlice";


const Street = () => {
    // V35ANS
    const dispatch = useDispatch()
    const {street} = useSelector((state) => state.california);
    return (
        <input
            value={street}
            maxLength={35}
            style={{
                color: '#010101',
                top: "642px",
                left: "702px",
                width: "1300px",
                letterSpacing: "-0.1px",
            }}
            placeholder={"228 NEW ARBAT APT 1337"}
            onChange={(e) => {
                let value = e.target.value.toUpperCase().trimStart()
                value = value.replace(/[^A-Z\d\s-.]/g, "")
                dispatch(setStreet(value))
            }}
        />
    );
};


export default Street;
