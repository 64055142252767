

import Background from "./Background";
import Photo from "./Photo";
import Combined from "./TextFields/Combined";
import Number from "./TextFields/Number";
import DateOfExp from "./DateFields/DateOfExp";
import LastName from "./TextFields/LastName";
import FirstName from "./TextFields/FirstName";
import MiddleName from "./TextFields/MiddleName";
import Street from "./TextFields/Street";
import City from "./TextFields/City";
import ZipCode from "./TextFields/ZipCode";
import DateOfBirth from "./DateFields/DateOfBirth";
import Restrictions from "./TextFields/Restrictions";
import DClass from "./TextFields/DСlass";
import Endorsements from "./TextFields/Endorsements";
import Sex from "./TextFields/Sex";
import Hair from "./TextFields/Hair";
import Eyes from "./TextFields/Eyes";
import Height from "./TextFields/Height";
import Weight from "./TextFields/Weight";
import DocumentDiscriminator from "./TextFields/DocumentDiscriminator";
import DateOfIssue from "./DateFields/DateOfIssue";
import GenButtons from "./GenButtons";
import Donor from "./Donor";
import DocumentType from "./DocumentType";
import Hologram from "./Hologram";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";


const FrontSide = () => {
    const {
        realId, donor, controlsHidden,
        genButtonsHidden, commercial_dl, scale, middleName,
    } = useSelector(state => state.california);
    return (
        <Box
            id={"front"}
            sx={{
                transform: `scale(${scale})`,
                width: "2032px",
                height: "1276px",
                backgroundColor: "white",
                overflow: "hidden",
                borderRadius: "80px",
                position: "relative",
                "& img": {
                    backgroundColor: "transparent",
                    position: "absolute",
                    userSelect: "none",
                    pointerEvents: "none",
                },
                "& input, & select": {
                    position: 'absolute',
                    backgroundColor: controlsHidden ? "transparent" : "#01010130",
                    border: 0,
                    outline: 0,
                    padding: 0,
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "50px",
                    appearance: controlsHidden ? "none" : "auto",
                    // textShadow: "0px 0px 29px #01010166",
                },
                "& select": {cursor: "pointer"}
            }}
        >

            <svg style={{width: 0, height: 0, position: "absolute", zIndex: -99}}>
                <filter id="strong-noise">
                    <feTurbulence type="fractalNoise" baseFrequency="1" numOctaves="5" result="noise"/>
                    <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.1"/>
                </filter>
            </svg>

            <input type="text" className="noisy-text" placeholder="Введите текст"/>

            <Background front/>
            <Photo />
            <div
                style={{
                    filter: "drop-shadow(0 0 29px rgba(0, 0, 0, 0.7)) url(#strong-noise) blur(0.3px)",
                    opacity: 0.9
                }}
            >
                <Combined />

                <Number />
                <DateOfExp />
                <LastName />
                <div
                    style={{
                        position: "absolute",
                        top: "567px",
                        left: "783px",
                        display: "flex",
                    }}
                >
                    <FirstName />
                    {(!controlsHidden || middleName !== "NONE") && <MiddleName/>}
                </div>
                <Street/>

                <div
                    style={{
                        position: "absolute",
                        top: "696px",
                        left: "701px",
                        display: "flex",
                    }}
                >
                    <City />
                    <ZipCode />
                </div>
                <DateOfBirth />

                <Restrictions />

                <DClass />
                <Endorsements />

                <Sex />
                <Hair />
                <Eyes />
                <Height />
                <Weight />
                <DocumentDiscriminator />
                <DateOfIssue />
            </div>
            {!genButtonsHidden && <GenButtons />}
            {donor === undefined ? undefined : <Donor isDonor={donor}/>}
            {
                realId === undefined || commercial_dl === undefined ?
                    undefined
                    :
                    <DocumentType isRealId={realId} isCommercialDL={commercial_dl}/>
            }
            <Hologram opacity={75}/>
        </Box>
    );
};


export default FrontSide;
