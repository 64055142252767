

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {authApi} from "./api/auth";
import californiaSlice from "./slices/californiaSlice";
import alertSlice from "./slices/alertSlice";


export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        california: californiaSlice,
        alert: alertSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authApi.middleware),
})


setupListeners(store.dispatch);
