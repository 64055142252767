

const Donor = ({
    isDonor
}) => {
    if (!isDonor)
        return
    return (
        <img
            src={`/static/psd/front/donor.png`}
            alt={"donor"}
            style={{
                top: "901px",
                left: "697px",
                width: "119px",
                height: "113px",
                zIndex: 9999,
            }}
        />
    );
};


export default Donor;
