

import {useDispatch, useSelector} from "react-redux";
import {setLastName} from "../../../../../redux/slices/californiaSlice";


const LastName = () => {
    // V40ANS
    const dispatch = useDispatch()
    const {lastName} = useSelector(state => state.california);
    return (
        <input
            maxLength={40}
            value={lastName}
            style={{
                color: '#010101',
                top: "495px",
                left: "783px",
                fontSize: "66.67px",
            }}
            placeholder={"GRIB"}
            onChange={(e) => {
                let value = e.target.value.toUpperCase().trimStart()
                value = value.replace(/[^A-Z\d\s-.]/g, "")
                dispatch(setLastName(value))
            }}
        />
    );
};


export default LastName;
