

import {useDispatch, useSelector} from "react-redux";
import {setCity} from "../../../../../redux/slices/californiaSlice";


const City = () => {
    // V20ANS
    const dispatch = useDispatch()
    const {city} = useSelector(state => state.california);

    const adjustWidth = (e) => {
        let value = e.target.value.toUpperCase().trimStart().replace(/[^A-Z\s\-.]/g, "");
        dispatch(setCity(value))
        let widthCalc = document.getElementById("widthCalc")

        widthCalc.style.font = getComputedStyle(e.target).font;
        widthCalc.textContent = value;
        e.target.style.width = `${widthCalc.offsetWidth+1}px`;
    }

    return (
        <>
            <input
                maxLength={20}
                value={city}
                style={{
                    position: "static",
                    color: '#010101',
                    minWidth: "72px",
                }}
                placeholder={"AA"}
                onChange={adjustWidth}
            />
            <span
                style={{
                    position: "absolute",
                    color: '#010101',
                    zIndex: -999,
                }}
                id={"widthCalc"}
            />
            <span
                style={{
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "50px",
                }}
            >
                ,&nbsp;CA&nbsp;
            </span>
        </>
    );
};


export default City;
