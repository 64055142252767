

import {useDispatch, useSelector} from "react-redux";
import {setNumber} from "../../../../../redux/slices/californiaSlice";


const Number = () => {
    // V25ANS
    const dispatch = useDispatch()
    const {number} = useSelector(state => state.california);
    return (
        <input
            value={number}
            maxLength={8}
            style={{
                color: '#84222a',
                top: "288px",
                left: "780px",
                fontSize: "90.11px",
                width: "435px",
            }}
            placeholder={"A0000000"}
            onChange={(e) => {
                let value = e.target.value.toUpperCase()
                if (!/^[A-Z][0-9]{0,7}$/.test(value))
                    value = value.slice(0, -1);
                dispatch(setNumber(value))
                e.target.value = value
            }}
        />
    );
};


export default Number;
