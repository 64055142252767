

import {Box} from "@mui/material";
import Header from "./Header";
import AlertDialog from "../AlertDialog";


const Layout = ({
    children,
}) => {
    return (
        <>
            <Header />

            <Box
                sx={{
                    pt: "112px",
                    pb: "56px",
                    minHeight: '100vh',     // todo: should we remove this ?
                    backgroundColor: 'background.default',
                    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)), url("/static/dirt.png")',
                }}
            >
                {children}
            </Box>
            <AlertDialog />
        </>
    );
};


export default Layout;
