

import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Slider,
    Switch
} from "@mui/material";
import {
    setCommercialDl,
    setControlsHidden,
    setDonor,
    setRealId,
    setVeteran,
    setScale, setMiddleName, setGenButtonsHidden, setInventoryNumber,
} from "../../../redux/slices/californiaSlice";
import {useDispatch, useSelector} from "react-redux";
import {toPng} from "html-to-image";
import {getRandomCharacter, getRandomNumberInRange} from "../../../utils/randomGen";
import {setOpenAlert} from "../../../redux/slices/alertSlice";


const Controls = () => {
    const dispatch = useDispatch();
    const {
        realId, donor, veteran, commercial_dl, scale, middleName, inventoryNumber, iss, number
    } = useSelector(state => state.california);
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "50px",
                zIndex: 9999,
                my: 5,
                color: "text.secondary"
            }}
        >
            <FormControl
                sx={{
                    alignItems: "center",
                }}
            >
                <FormLabel>Hide Controls</FormLabel>
                <Switch defaultChecked onChange={e => {dispatch(setControlsHidden(e.target.checked))}}/>
            </FormControl>
            <FormControl
                sx={{
                    alignItems: "center",
                }}
            >
                <FormLabel>Hide Gen Buttons</FormLabel>
                <Switch onChange={e => {dispatch(setGenButtonsHidden(e.target.checked))}}/>
            </FormControl>
            <FormControl
                sx={{
                    alignItems: "center",
                }}
            >
                <FormLabel>Scale (x{scale})</FormLabel>
                <Slider
                    min={0.1}
                    max={1.15}
                    step={0.025}
                    value={scale}
                    sx={{
                        width: "300px",
                    }}
                    onChange={e => {dispatch(setScale(e.target.value))}}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Middle Name?</FormLabel>
                <RadioGroup
                    onChange={(e) => {
                        dispatch(setMiddleName(e.target.value))
                    }}
                    value={middleName === "NONE" ? "NONE" : ""}
                >
                    <FormControlLabel value="" control={<Radio />} label="Exist" />
                    <FormControlLabel value="NONE" control={<Radio />} label="NONE" />
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel>Compliance</FormLabel>
                <RadioGroup
                    onChange={(e) => {
                        dispatch(setRealId(e.target.value === "1"))
                    }}
                    value={`${+realId}`}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Real ID" />
                    <FormControlLabel value="0" control={<Radio />} label="Federal Limits" />
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel>Type</FormLabel>
                <RadioGroup
                    onChange={(e) => {
                        dispatch(setCommercialDl(e.target.value === "1"))
                    }}
                    value={`${+commercial_dl}`}
                >
                    <FormControlLabel value="0" control={<Radio />} label="Default DL" />
                    <FormControlLabel disabled value="1" control={<Radio />} label="Commercial DL" />
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel>Donor</FormLabel>
                <RadioGroup
                    onChange={(e) => {
                        dispatch(setDonor(e.target.value === "1"))
                    }}
                    value={`${+donor}`}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel>Veteran</FormLabel>
                <RadioGroup
                    onChange={(e) => {
                        dispatch(setVeteran(e.target.value === "1"))
                    }}
                    value={`${+veteran}`}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
            <FormControl>
                <FormLabel>Inventory Number</FormLabel>
                <Input
                    id={"inventoryNumber"}
                    onChange={(e) => {
                        dispatch(setInventoryNumber(e.target.value))
                    }}
                    value={inventoryNumber}
                    placeholder={"YYXXXNNNNNNNNXX01"}
                >
                </Input>
                <Button
                    sx={{mt: 2}}
                    variant="text"
                    onClick={() => {
                        if (!iss || iss.length !== 10)
                            return dispatch(setOpenAlert({
                                title: "Error",
                                text: "Complete issued date field first"
                            }))
                        if (!number || number.length !== 8)
                            return dispatch(setOpenAlert({
                                title: "Error",
                                text: "Complete Driver License number field first"
                            }))
                        let newValue = `${iss.slice(-2)}${getRandomNumberInRange(100, 999)}${getRandomCharacter()}${number.slice(1)}${getRandomNumberInRange(10, 99)}01`
                        dispatch(setInventoryNumber(newValue))
                    }}
                >
                    Generate
                </Button>
            </FormControl>
            <FormControl
                sx={{
                    gap: 2
                }}
            >
                <Button
                    disabled
                    variant="outlined"
                    onClick={() => {
                        toPng(document.getElementById("front"), { quality: 1, })
                            .then((dataUrl) => {
                                const link = document.createElement('a')
                                link.download = 'front.png'
                                link.href = dataUrl
                                link.click()
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }}
                >
                    Download Front
                </Button>

                <Button
                    disabled
                    variant="outlined"
                    onClick={() => {
                        toPng(document.getElementById("back"), { quality: 1, })
                            .then((dataUrl) => {
                                const link = document.createElement('a')
                                link.download = 'back.png'
                                link.href = dataUrl
                                link.click()
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }}
                >
                    Download Back
                </Button>
            </FormControl>
        </Box>
    );
};


export default Controls;
