

const Hologram = ({
    opacity
}) => {
    return (
        <img
            src={`/static/psd/front/hologram.png`}
            alt={"hologram"}
            style={{
                mixBlendMode: "screen",
                opacity: opacity + "%",
                zIndex: 9999
            }}
        />
    );
};


export default Hologram;
