

import {AppBar} from "@mui/material";
import TopHeader from "./TopHeader";


const Header = () => {
    return (
        <AppBar position="fixed" elevation={0} sx={{zIndex: 999999}}>
            <TopHeader/>
        </AppBar>
    );
};


export default Header;
